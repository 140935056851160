import { ItemFilter } from '../pages/ProductSelection';

/**
 * Map of values between the ones are displayed in the App and the equivalent in DB
 */
const GendersAppToDbMap: { [key: string]: string[] } = {
  Unisex: ['Unisex'],
  "Men's": ['Mens'],
  "Women's": ['Ladies'],
  Youth: ['Youth'],
};

/**
 * Maps the string values of genders, from the DB, into ItemFilters used in the app
 * The values we show in the app are not the same as the ones we have in the DB
 * Transformation is required
 * @param {string[]} genders
 * @returns {ItemFilter[]}
 */
export const MapGendersToItemFilters = (genders: string[]): ItemFilter[] => {
  const genderItemFiltersKeys: string[] = [];
  const genderItemFilters: ItemFilter[] = [];
  const genderMapKeys: string[] = Object.keys(GendersAppToDbMap);

  genders.forEach((gender: string): void => {
    let mappedKey: string = '';
    for (let i = 0; i < genderMapKeys.length; i += 1) {
      if (GendersAppToDbMap[genderMapKeys[i]].indexOf(gender) > -1) {
        mappedKey = genderMapKeys[i];
        break;
      }
    }
    if (genderItemFiltersKeys.indexOf(mappedKey) === -1) {
      genderItemFiltersKeys.push(mappedKey);
      genderItemFilters.push({
        value: mappedKey.toLowerCase(),
        text: mappedKey,
        selected: false,
      });
    }
  });

  return genderItemFilters;
};

/**
 * Maps the array of gender values selected in the filters to the equivalent in the DB
 * @param {string[]} genders
 * @returns {string[]}
 */
export const MapGenderTextToDbValues = (genders: string[]): string[] => {
  return genders.flatMap((gender: string) => {
    const capitalizedGender: string = `${gender.charAt(0).toUpperCase()}${gender.slice(1)}`;
    return GendersAppToDbMap[capitalizedGender];
  });
};
