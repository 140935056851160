import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import Helmet from 'react-helmet';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Qs, { IParseOptions } from 'qs';
import { RouteComponentProps } from 'react-router-dom';

import { routes, updateURLQuery, url } from 'inkp-routes/public';
import {
  Query as QueryProductsResults,
  QueryfilterProductsArgs,
  Product,
  ProductColor,
  ProductCategory,
  Filters,
  ProductsWithFilters,
  DesignTemplate,
} from 'inkp-product-sdk/types.g';
import {
  prettyProductSize,
  PRODUCT_CATEGORY_MAP,
  PRODUCT_SUB_CATEGORY_MAP,
  CATEGORY_TYPE_TITLE_MAP,
} from 'inkp-product-sdk/product';
import { SHIPPING_SPEED_ENUM } from 'inkp-order-sdk/types.g';
import {
  TYPE_PRODUCT_CATEGORIES,
} from 'inkp-product-sdk/constants';
import mockupRoutes from 'inkp-mockup-sdk/routes';
// import { ITEMS_NUMBER_FOR_STARTING_PRICE } from 'inkp-order-sdk/quoter';

// Components
import Checkbox from 'inkp-components/dist/Components/Checkbox';
import Container from 'inkp-components/dist/Components/LeftFilter/Container';
import ContainerFilterMobile from 'inkp-components/dist/Components/MobileLeftFilter/ContainerFilterMobile';
// tslint:disable-next-line:max-line-length
import ContainerLeftFilterButtonMobile from 'inkp-components/dist/Components/MobileLeftFilter/ContainerLeftFilterButtonMobile';
import Header from 'inkp-components/dist/Components/MobileLeftFilter/Header';
import LabelFilterMobile from 'inkp-components/dist/Components/MobileLeftFilter/LabelFilterMobile';
import MobileBottomFilterControls from 'inkp-components/dist/Components/MobileBottomFilterControls';
import MobileBrandFilter, {
  Props as MobileBrandFilterButtonProps,
} from 'inkp-components/dist/Components/MobileBrandFilter';
import MobileColorFilter, {
  Props as MobileColorFilterButtonPros,
} from 'inkp-components/dist/Components/MobileColorFilter';
import PrimaryButton from 'inkp-components/dist/Components/PrimaryButton';
import ProductCardContainer from 'inkp-components/dist/Components/ProductCardContainer';
import ShowAll from 'inkp-components/dist/Components/LeftFilter/ShowAll';
import StyleFilter from 'inkp-components/dist/Components/StyleFilter';
import TopRightFilter from 'inkp-components/dist/Components/TopRightFilter';
import Loading from 'inkp-components/dist/Components/Loading';
import WithInfiniteScroll from 'inkp-components/dist/HOC/WithInfiniteScroll';
import { Option } from 'inkp-components/dist/Components/DropDown';
// tslint:disable-next-line:max-line-length
import { Props as LeftFilterButtonMobileProps } from 'inkp-components/dist/Components/MobileLeftFilter/LeftFilterButtonMobile';
import { Props as ProductProps, Tag, TagBgColors } from 'inkp-components/dist/Components/ProductCard';

// Helpers
import { MapGendersToItemFilters, MapGenderTextToDbValues } from '../../helpers/gender';
import { sortSizes, getProductIcons, SIDES_BY_ORDER } from '../../util/Product';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

// Constants
const ITEM_PER_PAGE: number = 10;
const ALL_STYLE_BUTTON_TEXT: string = 'All Styles';
const BASE_DISPLAY: number = 5;
const CATEGORY_SCOPE_TYPE: string = 'type';
const UNCATEGORIZED_LABEL: string = 'uncategorized';
const ITEMS_NUMBER_FOR_STARTING_PRICE = 50;
const DELIVERY_SPEED_MAP: { [key: string]: string } = {
  [SHIPPING_SPEED_ENUM.THREE_DAY]: 'Lightning 3 Day',
  [SHIPPING_SPEED_ENUM.FOUR_DAY]: 'Standard 4 Day',
  [SHIPPING_SPEED_ENUM.NINE_DAY]: 'Stress Free 9 Day',
};
const DELIVERY_SORT_MAP: { [key: string]: number } = {
  [SHIPPING_SPEED_ENUM.THREE_DAY]: 1,
  [SHIPPING_SPEED_ENUM.FOUR_DAY]: 10,
  [SHIPPING_SPEED_ENUM.NINE_DAY]: 100,
};
const SortByFilters: Option[] = [
  { key: 'popular', display: 'Most Popular' },
  { key: 'low-to-high', display: '$ Low → High' },
  { key: 'high-to-low', display: '$ High → Low' },
  { key: 'newest', display: 'Newest' },
];

const SortByFilterValues: any[] = [
  { field: 'rank', order: 1 },
  { field: 'colors.startingPrice', order: 1 },
  { field: 'colors.startingPrice', order: -1 },
  { field: 'createdAt', order: -1 },
];

const DEFAULT_MOCK_SIZE: string = 'medium';
const DEFAULT_MOCKUP_COLOR: string = 'ffffff';
const DEFAULT_MOCKUP_SIDE: string = 'FRONT';
const DEFAULT_CATEGORY_TITLE = 'T-Shirts, Sweatshirts, and More';

export const QUERY_PRODUCTS = gql`
  query ProductFilter(
    $brands: [String!]
    $colors: [String!]
    $genders: [String!]
    $sizes: [String!]
    $speeds: [SHIPPING_SPEED_ENUM!]
    $styles: [String!]
    $categories: [ProductCategoryInput!]
    $sortBy: [SortSearchProduct!]
    $page: Int!
    $perPage: Int!
  ) {
    filterProducts(
      brands: $brands
      colors: $colors
      genders: $genders
      sizes: $sizes
      styles: $styles
      speeds: $speeds
      categories: $categories
      sortBy: $sortBy
      page: $page
      perPage: $perPage
    ) {
      items {
        id
        name
        detailTemplates {
          id
          side
        }
        colors {
          name
          hex
          inStock
          startingPrice
          class
          images {
            label
            url
          }
          sizes
        }
        image {
          label
          url
        }
        categories {
          scope
          name
        }
      }
      filters {
        brands
        colors
        genders
        sizes
        styles
        speeds
      }
      pageMetadata {
        count
        page
        pages
      }
    }
  }
`;

class QueryProducts extends Query<QueryProductsResults, QueryfilterProductsArgs> {}

interface ItemFilter {
  value: string;
  text: string;
  selected: boolean;
}
interface Props {
  category?: string;
}

interface State {
  brands: ItemFilter[];
  categories: ProductCategory[];
  colors: ItemFilter[];
  count: number;
  filters: Filters;
  genders: ItemFilter[];
  isFirstRender: boolean;
  lastPageReached: boolean;
  loading: boolean;
  page: number;
  perPage: number;
  selectedTopRightFilterIndex: number;
  showAllBrands: boolean;
  showAllColors: boolean;
  showAllSizes: boolean;
  showBrands: boolean;
  showColors: boolean;
  showDeliverySpeeds: boolean;
  showGenders: boolean;
  showMobileBrandFilters: boolean;
  showMobileColorFilters: boolean;
  showMobileFilters: boolean;
  showSizes: boolean;
  sizes: ItemFilter[];
  skipFilters: boolean;
  sortBy: any[];
  styles: ItemFilter[];
  speeds: ItemFilter[];
}

interface ProductSelectionProps extends RouteComponentProps<Props> {}

const parseOptions: IParseOptions = {
  ignoreQueryPrefix: true,
};
export default class ProductSelection extends React.Component<ProductSelectionProps> {
  public state: State = {
    brands: [],
    categories: [],
    colors: [],
    count: 0,
    filters: { brands: [], colors: [], genders: [], sizes: [], styles: [], speeds: [] },
    genders: [],
    isFirstRender: true,
    lastPageReached: false,
    loading: false,
    page: 1,
    perPage: ITEM_PER_PAGE,
    selectedTopRightFilterIndex: 0,
    showAllBrands: false,
    showAllColors: false,
    showAllSizes: false,
    showBrands: true,
    showColors: true,
    showDeliverySpeeds: true,
    showGenders: true,
    showMobileBrandFilters: false,
    showMobileColorFilters: false,
    showMobileFilters: false,
    showSizes: true,
    sizes: [],
    skipFilters: false,
    sortBy: [SortByFilterValues[0]],
    styles: [],
    speeds: [],
  };

  constructor(props: ProductSelectionProps) {
    super(props);
    const {
      location: { search },
      match: { params },
    } = props;
    const categories: ProductCategory[] = [];
    if (params.category) {
      categories.push({
        scope: 'type',
        name: params.category,
      });
    }
    const queryObj = Qs.parse(search, parseOptions);
    const filters = Object.assign({}, { ...this.state.filters }, { ...queryObj.filters });
    this.state = {
      ...this.state,
      filters,
      categories,
    };
  }

  private onToggleOpen = (toggleName: any) => (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState((state: any) => ({ [toggleName]: !state[toggleName] }));
  };

  private onShowAllOpen = (showAllName: any) => (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState((state: any) => ({ [showAllName]: !state[showAllName] }));
  };

  private changeFilter = (stateArray: ItemFilter[], value: string, filterName: keyof Filters) => {
    const filterArray = this.state.filters[filterName];
    const filterArraySet = new Set(filterArray);
    if (filterArraySet.has(value)) {
      filterArraySet.delete(value);
    } else {
      filterArraySet.add(value);
    }
    this.setState((state: any) => ({
      filters: Object.assign({}, { ...state.filters }, { [filterName]: [...Array.from(filterArraySet)] }),
      [filterName]: stateArray,
      lastPageReached: false,
      page: 1,
    }));
    this.updateUrl({ [filterName]: [...Array.from(filterArraySet)] });
  };

  private resetFilter = (filterName: keyof Filters): void => {
    this.setState((state: any) => ({
      filters: Object.assign({}, { ...state.filter }, { [filterName]: [] }),
    }));
    this.updateUrl({ [filterName]: [] });
  };

  private onQueryCompletion = (data: QueryProductsResults) => {
    if (!data.filterProducts) {
      return;
    }
    const productsWithFilters: ProductsWithFilters = data.filterProducts;
    const { filters } = productsWithFilters;
    this.setFilters(filters);

    if (
      data.filterProducts &&
      data.filterProducts.pageMetadata &&
      this.state.count !== data.filterProducts.pageMetadata.count
    ) {
      this.setState({ count: data.filterProducts.pageMetadata.count });
    }
  };

  private setFilters(filters: Filters) {
    const { brands, colors, genders, sizes, styles, speeds } = filters;
    let filteredStyles: string[] = [];

    const mappedBrands: ItemFilter[] = brands
      ? brands.sort().map((brand: string) => {
          return { value: brand.toLowerCase(), text: brand, selected: false };
        })
      : [];

    const mappedColors: ItemFilter[] = colors
      ? colors.sort().map((color) => {
          return {
            value: color.toLowerCase(),
            text: _.capitalize(color),
            selected: false,
          };
        })
      : [];
    mappedColors.sort();

    const validGenders: string[] = genders ? genders.filter((currentGender: string): string => currentGender) : [];
    const mappedGenders: ItemFilter[] = MapGendersToItemFilters(validGenders);
    mappedGenders.reverse();

    const mappedSizes: ItemFilter[] = sizes
      ? sortSizes(sizes).map((size: string) => ({
          value: size,
          text: prettyProductSize(size),
          selected: false,
        }))
      : [];

    if (this.state.filters.styles) {
      filteredStyles = this.state.filters.styles;
    }
    const allStylesItemFilter: ItemFilter = {
      value: '',
      text: ALL_STYLE_BUTTON_TEXT,
      selected: filteredStyles.length === 0 ? true : false,
    };
    const mappedStyles: ItemFilter[] = styles
      ? styles.map((style: string) => {
          const isSelected: boolean = filteredStyles.indexOf(style.toLowerCase()) !== -1;
          return { value: style.toLowerCase(), text: style, selected: isSelected };
        })
      : [];
    const mappedStylesWithAllStyles: ItemFilter[] = [allStylesItemFilter, ...mappedStyles];

    const mappedSpeeds: ItemFilter[] = speeds
      ? speeds.map((speed) => {
          return {
            value: speed,
            text: DELIVERY_SPEED_MAP[speed],
            selected: false,
          };
        })
      : [];
    _.sortBy(mappedSpeeds, (mappedSpeed: ItemFilter) => {
      return DELIVERY_SORT_MAP[mappedSpeed.value];
    });

    this.setState({
      brands: mappedBrands,
      colors: mappedColors,
      genders: mappedGenders,
      isFirstRender: false,
      sizes: mappedSizes,
      skipFilters: true,
      styles: mappedStylesWithAllStyles,
      speeds: mappedSpeeds,
    });
  }

  private isFilterSelected = (category: keyof Filters, key: string) => {
    const isSelected = Array.prototype.includes.apply(this.state.filters[category], [key]);
    return isSelected;
  };

  private updateUrl = (newFilter: any) => {
    const {
      location: { search, pathname },
    } = this.props;
    const filters: any = {};
    for (const [key, value] of Object.entries(newFilter)) {
      filters[key] = value;
    }
    const newFilters: Filters = Object.assign({}, { ...this.state.filters }, filters);
    const searchObj: any = Qs.parse(search, parseOptions);
    const res = updateURLQuery(pathname, searchObj, { filters: newFilters }, { encodeValuesOnly: true });
    const newSearch: string = res.replace(pathname, '');

    this.props.history.push({
      pathname,
      search: newSearch,
      state: {
        filters,
      },
    });
  };

  private moveToNextPage = (fetchMore: any, variables: any, currentData: QueryProductsResults | undefined) => {
    let loading: boolean;
    return () => {
      if (!currentData || !currentData.filterProducts) {
        return;
      }
      const filterProducts: ProductsWithFilters = currentData.filterProducts;
      const { page, pages } = filterProducts.pageMetadata;
      const nextpage = page + 1;
      const lastPageReached = pages === page;

      if (loading || lastPageReached) {
        if (lastPageReached) return this.setState({ lastPageReached });
        return;
      }

      loading = true;

      this.setState({ loading });

      variables.page = nextpage;

      fetchMore({
        variables: {
          ...variables,
        },
        updateQuery: (prev: QueryProductsResults, { fetchMoreResult: next }: any) => {
          const result = { ...prev };

          if (next && next.filterProducts.items.length && result.filterProducts && result.filterProducts.items) {
            result.filterProducts.items = [...result.filterProducts.items, ...next.filterProducts.items];
            result.filterProducts.pageMetadata.page = nextpage;
          }

          this.setState({ loading: false, count: next.filterProducts.pageMetadata.count });
          loading = false;

          return result;
        },
      });
    };
  };

  private mapFiltersForTopRightBar = () => {
    const {
      brands: baseBrands,
      colors: baseColors,
      sizes: baseSizes,
      genders: baseGenders,
      speeds: baseSpeeds,
    } = this.state;
    const { colors, brands, sizes, genders, speeds } = this.state.filters;
    const filters: any = [];
    colors &&
      colors.forEach((color) => {
        const mappedColor = _.find(baseColors, { value: color });
        if (!mappedColor) return;
        filters.push(this.createTopRightFilterElement('colors', mappedColor));
      });
    brands &&
      brands.forEach((brand) => {
        const mappedBrand = _.find(baseBrands, { value: brand });
        if (!mappedBrand) return;
        filters.push(this.createTopRightFilterElement('brands', mappedBrand));
      });
    sizes &&
      sizes.forEach((size) => {
        const mappedSize = _.find(baseSizes, { value: size });
        if (!mappedSize) return;
        filters.push(this.createTopRightFilterElement('sizes', mappedSize));
      });

    genders &&
      genders.forEach((gender) => {
        const mappedGender = _.find(baseGenders, { value: gender });
        if (!mappedGender) return;
        filters.push(this.createTopRightFilterElement('genders', mappedGender));
      });

    speeds &&
      speeds.forEach((speed) => {
        const mappedSpeed = _.find(baseSpeeds, { value: speed });
        if (!mappedSpeed) return;
        filters.push(this.createTopRightFilterElement('speeds', mappedSpeed));
      });

    return filters;
  };

  private createTopRightFilterElement = (category: keyof Filters, filter: { value: string; text: string }) => {
    const { value, text } = filter;
    return {
      display: text,
      onRemove: () => {
        const state = { ...this.state };

        // tslint:disable-next-line
        state.filters[category] = state.filters[category].filter((filterElement: string) => {
          return filterElement.toLowerCase() !== value.toLowerCase();
        });

        this.updateUrl(state.filters);

        return this.setState({
          ...state,
        });
      },
    };
  };

  private mapProducts = (data: QueryProductsResults | undefined): ProductProps[] => {
    if (!data) {
      return [];
    }
    const currentFilterColors: string[] = this.state.filters.colors
      ? this.state.filters.colors.slice().map((c) => c.toLowerCase())
      : [];
    if (!data || !data.filterProducts || !data.filterProducts.items) {
      return [];
    }

    return data.filterProducts.items.map((product: Product) => {
      const { id, name, colors, image, categories } = product;

      let productDisplayColor = DEFAULT_MOCKUP_COLOR;
      let productDisplayColorName = '';
      let imageUrl = image.url;
      let templateIdentifier: string = '';
      let mockupTemplate: DesignTemplate | undefined;

      const categoryType: ProductCategory | undefined =
        categories && categories.find((category: ProductCategory) => category.scope === CATEGORY_SCOPE_TYPE);
      const categoryTypeName: string = categoryType ? categoryType.name : UNCATEGORIZED_LABEL;
      let filteredColorsForProduct: ProductColor[] = product.colors.filter((productColor: ProductColor) => productColor.inStock);
      if (currentFilterColors.length > 0) {
        filteredColorsForProduct = filteredColorsForProduct.filter((productColor: ProductColor) => {
          const productColorClass: string = (productColor.class || '').toLowerCase();
          return currentFilterColors.indexOf(productColorClass) > -1;
        });
      }
      const lowestStartingPriceForFilteredColors: number = filteredColorsForProduct.reduce(
        (prev: number, current: ProductColor): number => {
          const currentPrice: number = current.startingPrice;
          if (currentPrice < prev) {
            return currentPrice;
          }
          return prev;
        },
        Infinity
      );

      if (filteredColorsForProduct.length > 0 && filteredColorsForProduct[0].hex) {
        const filteredColor = filteredColorsForProduct[0];
        productDisplayColor = filteredColor.hex;
        productDisplayColorName = filteredColor.name;
        if (filteredColor.images && filteredColor.images.length > 0) {
          const sortedImages = _.sortBy(filteredColor.images, (image) => {
            return SIDES_BY_ORDER.indexOf(image.label);
          });
          imageUrl = sortedImages[0].url;
        }
      }

      if (product.detailTemplates && product.detailTemplates.length > 0) {
        mockupTemplate = product.detailTemplates.find((detailTemplate: DesignTemplate) => {
          return detailTemplate.side === DEFAULT_MOCKUP_SIDE;
        });
      }

      if (mockupTemplate) {
        templateIdentifier = mockupTemplate.id;
        const mockupUrlParams = { templateIdentifier, color: productDisplayColor, size: DEFAULT_MOCK_SIZE };
        // comment below for local development
        const mockupUrl: string = url('mockup', mockupRoutes.blank, mockupUrlParams);
        imageUrl = mockupUrl;
      }

      const { tag, topRightIcon } = getProductIcons(id);

      return {
        id,
        key: id,
        name,
        image: imageUrl,
        price: Math.round(lowestStartingPriceForFilteredColors / ITEMS_NUMBER_FOR_STARTING_PRICE),
        colorCount: colors.length,
        pathname: `${routes.app.product.base}/${categoryTypeName}/${id}`,
        search: Qs.stringify({ color: productDisplayColorName }),
        tag,
        topRightIcon,
      };
    });
  };

  private onTopRightFilterChange = (option: Option) => {
    const selectedTopRightFilterIndex = SortByFilters.findIndex((filter: Option) => filter.key === option.key);
    this.setState({ selectedTopRightFilterIndex, sortBy: SortByFilterValues[selectedTopRightFilterIndex] });
  };

  private onFiltersClear = () => {
    const newFiltersState: Filters = {
      brands: [],
      colors: [],
      sizes: [],
      styles: this.state.filters.styles,
      genders: [],
      speeds: [],
    };
    this.setState({
      filters: newFiltersState,
      lastPageReached: false,
      page: 1,
    });
    this.updateUrl(newFiltersState);
  };

  private onStyleButtonClick = (index: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { styles } = this.state;
    let newStyleFilterArray: string[] = [];

    const updatedStyles: ItemFilter[] = styles.map((style: ItemFilter, idx: number) => {
      if (index === 0) {
        style.selected = false;
      } else if (index === idx) {
        style.selected = !style.selected;
      }
      if (style.selected) {
        newStyleFilterArray.push(style.value);
      }

      return style;
    });

    if (newStyleFilterArray.length === 0) {
      newStyleFilterArray.push(styles[0].value);
      updatedStyles[0].selected = true;
    }

    newStyleFilterArray = newStyleFilterArray.filter((styleFilter: string) => styleFilter);
    this.setState((state: State) => ({
      filters: Object.assign({}, { ...state.filters }, { styles: [...newStyleFilterArray] }),
      styles: updatedStyles,
      lastPageReached: false,
      page: 1,
    }));
    this.updateUrl({ styles: [...newStyleFilterArray] });
  };

  private scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(this.scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  private onGoToTopButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.scrollToTop();
  };

  private toggleMobileFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    return this.setState({
      showMobileFilters: !this.state.showMobileFilters,
    });
  };

  private onMobileFiltersResetButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    return this.onFiltersClear();
  };

  private createSortByMobileFilterButtonObjects = (selectedTopRightFilterIndex: number) => {
    return SortByFilters.map(
      (option: Option, index: number): LeftFilterButtonMobileProps => {
        const isSelected: boolean = index === selectedTopRightFilterIndex;
        return {
          key: option.key,
          buttonText: option.display,
          name: '',
          selected: isSelected,
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
            return this.onTopRightFilterChange(option);
          },
        };
      }
    );
  };

  private createGenderMobileFilterButtonObjects = (genders: ItemFilter[]): LeftFilterButtonMobileProps[] => {
    return genders.map(
      (gender: ItemFilter): LeftFilterButtonMobileProps => {
        const selected = this.isFilterSelected('genders', gender.value);
        return {
          key: gender.value,
          buttonText: gender.text,
          name: '',
          selected,
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
            return this.changeFilter(genders, gender.value, 'genders');
          },
        };
      }
    );
  };

  private createDeliverySpeedMobileFilterButtonObjects = (speeds: ItemFilter[]): LeftFilterButtonMobileProps[] => {
    return speeds.map(
      (speed: ItemFilter): LeftFilterButtonMobileProps => {
        const selected = this.isFilterSelected('speeds', speed.value);
        return {
          key: speed.value,
          buttonText: DELIVERY_SPEED_MAP[speed.value],
          name: '',
          selected,
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
            return this.changeFilter(speeds, speed.value, 'speeds');
          },
        };
      }
    );
  };

  private createSizeMobileFilterButtonObjects = (sizes: ItemFilter[]): LeftFilterButtonMobileProps[] => {
    return sizes.map(
      (size: ItemFilter): LeftFilterButtonMobileProps => {
        const selected = this.isFilterSelected('sizes', size.value);
        return {
          key: size.value,
          buttonText: size.text,
          name: '',
          selected,
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
            return this.changeFilter(sizes, size.value, 'sizes');
          },
        };
      }
    );
  };

  private toggleColorMobileFilters = (event: React.MouseEvent<HTMLButtonElement>): void => {
    return this.setState({
      showMobileColorFilters: !this.state.showMobileColorFilters,
    });
  };

  private onColorMobileFiltersResetButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    return this.resetFilter('colors');
  };

  private createColorMobileFilterButtonObjects = (colors: ItemFilter[]): MobileColorFilterButtonPros[] => {
    return colors.map(
      (color: ItemFilter): MobileColorFilterButtonPros => {
        const selected = this.isFilterSelected('colors', color.value);
        return {
          hex: color.value,
          selected,
          text: color.text,
          value: color.value,
          onChange: (event: React.MouseEvent<HTMLButtonElement>) => {
            return this.changeFilter(colors, color.value, 'colors');
          },
        };
      }
    );
  };

  private renderColorMobileFilters = (colorsBase: ItemFilter[], filteredColors: string[]) => {
    const enableReset: boolean = filteredColors.length > 0;
    return (
      <div className="d-b md:d-n p-fixed pin bgc-white">
        <ContainerFilterMobile>
          <Header
            title="Colors"
            onClose={this.toggleColorMobileFilters}
            onReset={enableReset ? this.onColorMobileFiltersResetButtonClick : undefined}
          />
        </ContainerFilterMobile>
        <div className="flex flex-wrap">
          {this.createColorMobileFilterButtonObjects(colorsBase).map(
            ({ hex, selected, text, value, onChange }, index) => {
              return (
                <MobileColorFilter
                  key={text}
                  text={text}
                  hex={hex}
                  value={value}
                  selected={selected}
                  onChange={onChange}
                  className={classnames('w-1/2 bwb-1 bc-gray', {
                    'bwr-1': index === colorsBase.length - 1 && index % 2 === 0,
                  })}
                />
              );
            }
          )}
        </div>
        <div className="p-absolute pin-b w-full bc-gray bwt-1 p-1">
          <PrimaryButton block={true} onClick={this.toggleColorMobileFilters}>
            Done
          </PrimaryButton>
        </div>
      </div>
    );
  };

  private toggleBrandMobileFilters = (event: React.MouseEvent<HTMLButtonElement>): void => {
    return this.setState({
      showMobileBrandFilters: !this.state.showMobileBrandFilters,
    });
  };

  private onBrandMobileFiltersResetButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    return this.resetFilter('brands');
  };

  private createBrandMobileFilterButtonObject = (brands: ItemFilter[]): MobileBrandFilterButtonProps[] => {
    return brands.map(
      (brand: ItemFilter): MobileBrandFilterButtonProps => {
        const selected: boolean = this.isFilterSelected('brands', brand.value);
        return {
          selected,
          text: brand.text,
          value: brand.value,
          onChange: (event: React.MouseEvent<HTMLButtonElement>) => {
            return this.changeFilter(brands, brand.value, 'brands');
          },
        };
      }
    );
  };

  private renderBrandMobileFilters = (brandsBase: ItemFilter[], filteredBrands: string[]) => {
    const enableReset: boolean = filteredBrands.length > 0;
    return (
      <div className="d-b md:d-n p-fixed pin bgc-white">
        <ContainerFilterMobile>
          <Header
            title="Brands"
            onClose={this.toggleBrandMobileFilters}
            onReset={enableReset ? this.onBrandMobileFiltersResetButtonClick : undefined}
          />
        </ContainerFilterMobile>
        <div className="flex flex-wrap">
          {this.createBrandMobileFilterButtonObject(brandsBase).map(({ selected, text, value, onChange }, index) => {
            return (
              <MobileBrandFilter
                key={text}
                text={text}
                value={value}
                selected={selected}
                onChange={onChange}
                className={classnames('w-1/2', {
                  'bwr-1': index % 2 === 0,
                })}
              />
            );
          })}
        </div>
        <div className="p-absolute pin-b w-full bc-gray bwt-1 p-1">
          <PrimaryButton block={true} onClick={this.toggleBrandMobileFilters}>
            Done
          </PrimaryButton>
        </div>
      </div>
    );
  };

  private renderGenderFilters = (genders: ItemFilter[]) => {
    return (
      <ul className="list-reset">
        {this.createGenderMobileFilterButtonObjects(genders).map(({ key, buttonText, name, onClick, selected }) => {
          return (
            <li key={key}>
              <Checkbox
                classNames="py-p5 px-1 bgc-navy-100:hover cursor-pointer"
                text={buttonText}
                onChange={onClick}
                selected={selected}
              />
            </li>
          );
        })}
      </ul>
    );
  };

  private renderColorFilters = (colors: ItemFilter[]) => {
    const showAllColors: boolean = this.state.showAllColors;
    const colorsToDisplay: ItemFilter[] = showAllColors ? colors : colors.slice(0, BASE_DISPLAY);
    return (
      <div>
        <ul className="list-reset">
          {this.createColorMobileFilterButtonObjects(colorsToDisplay).map(
            ({ hex, selected, text, value, onChange }) => {
              return (
                <li key={text}>
                  <MobileColorFilter
                    text={text}
                    hex={hex}
                    onChange={onChange}
                    selected={selected}
                    value={value}
                    className="py-p5 px-1 bgc-navy-100:hover cursor-pointer"
                  />
                </li>
              );
            }
          )}
        </ul>
        {colors.length > BASE_DISPLAY && (
          <ShowAll
            text="show all colors"
            open={showAllColors}
            onClick={this.onShowAllOpen('showAllColors')}
            className="md:bgc-navy-100:hover"
          />
        )}
      </div>
    );
  };

  private renderDeliveryFilters = (speeds: ItemFilter[]) => {
    return (
      <ul className="list-reset">
        {this.createDeliverySpeedMobileFilterButtonObjects(speeds).map(
          ({ key, buttonText, name, selected, onClick }) => {
            return (
              <li key={key}>
                <Checkbox
                  classNames="py-p5 px-1 bgc-navy-100:hover cursor-pointer"
                  text={buttonText}
                  onChange={onClick}
                  selected={selected}
                />
              </li>
            );
          }
        )}
      </ul>
    );
  };

  private renderBrandFilters = (brands: ItemFilter[]) => {
    const showAllBrands: boolean = this.state.showAllBrands;
    const brandsToDisplay: ItemFilter[] = showAllBrands ? brands : brands.slice(0, BASE_DISPLAY);
    return (
      <div>
        <ul className="list-reset">
          {this.createBrandMobileFilterButtonObject(brandsToDisplay).map(({ text, value, selected, onChange }) => {
            return (
              <li key={value}>
                <Checkbox
                  classNames="py-p5 px-1 bgc-navy-100:hover cursor-pointer"
                  text={text}
                  onChange={onChange}
                  selected={selected}
                />
              </li>
            );
          })}
        </ul>
        {brands.length > BASE_DISPLAY && (
          <ShowAll
            text="show all brands"
            open={showAllBrands}
            onClick={this.onShowAllOpen('showAllBrands')}
            className="md:bgc-navy-100:hover"
          />
        )}
      </div>
    );
  };

  private renderSizeFilters = (sizes: ItemFilter[]) => {
    const showAllSizes: boolean = this.state.showAllSizes;
    const sizesToDisplay: ItemFilter[] = showAllSizes ? sizes : sizes.slice(0, BASE_DISPLAY);
    return (
      <div>
        <ul className="list-reset">
          {this.createSizeMobileFilterButtonObjects(sizesToDisplay).map(
            ({ key, buttonText, name, selected, onClick }) => {
              return (
                <li key={key}>
                  <Checkbox
                    classNames="py-p5 px-1 bgc-navy-100:hover cursor-pointer"
                    text={buttonText}
                    onChange={onClick}
                    selected={selected}
                  />
                </li>
              );
            }
          )}
        </ul>
        {sizes.length > BASE_DISPLAY && (
          <ShowAll
            text="show all sizes"
            open={showAllSizes}
            onClick={this.onShowAllOpen('showAllSizes')}
            className="md:bgc-navy-100:hover"
          />
        )}
      </div>
    );
  };

  private getSubCategory(category?: string, genderFilters?: string[], styleFilters?: string[]) {
    switch (category) {
      case TYPE_PRODUCT_CATEGORIES.T_SHIRTS:
      case TYPE_PRODUCT_CATEGORIES.JACKETS:
        const gender = genderFilters && genderFilters.length > 0 ? genderFilters[0] : '';
        return gender;
      case TYPE_PRODUCT_CATEGORIES.SWEATSHIRTS:
        const style = styleFilters && styleFilters.length > 0 ? styleFilters[0] : '';
        return style;
      default:
        return '';
    }
  }

  private prettyCategoryAndSubCategory(category: string, subCategory: string) {
    const prettySubCategory = PRODUCT_SUB_CATEGORY_MAP[subCategory.toLowerCase()];
    const prettyCategory = PRODUCT_CATEGORY_MAP[category.toLowerCase() as TYPE_PRODUCT_CATEGORIES];
    if (!prettySubCategory && !prettyCategory) {
      return category;
    }
    if (!prettySubCategory) {
      return prettyCategory;
    }
    return `${prettySubCategory} ${prettyCategory}`;
  }

  static getDerivedStateFromProps(props: ProductSelectionProps, state: State) {
    const {
      location: { search, state: locationState },
      match: { params },
    } = props;
    if (locationState && locationState.fromHeader) {
      const categories: ProductCategory[] = [];
      if (params.category) {
        categories.push({
          scope: 'type',
          name: params.category,
        });
      }
      const queryObj = Qs.parse(search, parseOptions);
      const emptyFilters: Filters = {
        brands: [],
        colors: [],
        genders: [],
        sizes: [],
        styles: [],
        speeds: [],
      };
      const filters = Object.assign({}, { ...emptyFilters }, { ...queryObj.filters });
      return {
        ...state,
        filters,
        categories,
        isFirstRender: true,
      };
    }
    return null;
  }

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    const {
      brands: baseBrands,
      categories,
      colors: baseColors,
      speeds: baseSpeeds,
      filters,
      genders: baseGenders,
      loading: pageLoading,
      page,
      perPage,
      showBrands,
      showColors,
      showDeliverySpeeds,
      showGenders,
      showSizes,
      sizes: baseSizes,
      skipFilters,
      sortBy,
      styles: baseStyles,
      lastPageReached,
      selectedTopRightFilterIndex,
      count,
      showMobileFilters,
      showMobileColorFilters,
      showMobileBrandFilters,
    } = this.state;
    const {
      location: { search },
      match: { params },
    } = this.props;
    const { brands, colors, genders, sizes, styles, speeds } = filters;

    const genderFilters: string[] = genders ? MapGenderTextToDbValues(genders) : [];

    const categoryType: ProductCategory | undefined =
      categories && categories.find((category: ProductCategory) => category.scope === CATEGORY_SCOPE_TYPE);
    const categoryTypeName: string = categoryType ? categoryType.name : UNCATEGORIZED_LABEL;
    const categoryTitle = CATEGORY_TYPE_TITLE_MAP[categoryTypeName] || DEFAULT_CATEGORY_TITLE;

    const subCategory = this.getSubCategory(params.category, genderFilters, styles);
    const categoryAndSubCategory = this.prettyCategoryAndSubCategory(params.category || '', subCategory);
    return (
      <React.Fragment>
        <Helmet>
          <title>{`Custom ${categoryTitle} - Ships FREE`}</title>
          <meta
            name="description"
            content={`Get your design or logo custom printed on ${categoryTitle}. Design online and get them delivered FAST & FREE. Guaranteed best quality!`}
          />
        </Helmet>
        <div className="w-full">
          <h1 className="title px-1 md:w-container md:mx-auto md:px-0 fs-xl md:fs-2xl fw-extra-bold mt-1">
            <style jsx>
              {`
                .title {
                  line-height: 1.25;
                }
              `}
            </style>
            {categoryTitle}
          </h1>

          <div className="flex flex-row flex-no-wrap md:justify-center items-stretch w-full mt-p5 md:mt-1">
            <div className="style-filter-container w-auto overflow-y-auto md:w-container">
              <style jsx>
                {`
                  .style-filter-container {
                    overflow-y: scroll;
                    scrollbar-width: none; /* Firefox */
                    -ms-overflow-style: none;  /* IE 10+ */
                  }
                  .style-filter-container::-webkit-scrollbar { /* WebKit */
                    width: 0;
                    height: 0;
                  }
                `}
              </style>
              <StyleFilter
                buttons={baseStyles.map(style => _.startCase(style.text))}
                selectedButtonIndices={baseStyles.reduce((accumulator: number[], style: ItemFilter, styleIdx) => {
                  if (style.selected) accumulator.push(styleIdx);
                  return accumulator;
                }, [])}
                onElementClicked={this.onStyleButtonClick}
              />
            </div>
          </div>
          <div className="flex flex-row flex-no-wrap justify-center items-stretch w-full">
            <div className="w-container mb-4 lg:mb-8">
              <div className="w-full">
                <div className="d-n md:d-b md:mt-2">
                  <TopRightFilter
                    options={SortByFilters}
                    selectedOptionIndex={selectedTopRightFilterIndex}
                    filterSelections={this.mapFiltersForTopRightBar()}
                    resultCount={count}
                    onFilterChange={this.onTopRightFilterChange}
                    onClearFilters={this.onFiltersClear}
                  />
                </div>
                <div className="d-n md:d-ib w-1/4 align-top mt-2">
                  <QueryProducts
                    query={QUERY_PRODUCTS}
                    variables={{
                      brands,
                      colors,
                      genders: genderFilters,
                      sizes,
                      styles,
                      speeds,
                      categories,
                      sortBy,
                      page,
                      perPage,
                    }}
                    skip={skipFilters}
                    onCompleted={(data: QueryProductsResults) => this.onQueryCompletion(data)}
                  >
                    {({ error, loading, data }) => {
                      if (loading) return <div></div>;
                      if (error) {
                        console.error(error);
                        return <div>error</div>;
                      }
                      if (data) {
                        this.onQueryCompletion(data);
                      }

                      return (
                        <div>
                          <Container
                            label="gender"
                            showToggle={true}
                            open={showGenders}
                            roundedTop={true}
                            onToggleOpen={this.onToggleOpen('showGenders')}
                          >
                            {this.renderGenderFilters(baseGenders)}
                          </Container>
                          <Container
                            label="color"
                            showToggle={true}
                            open={showColors}
                            onToggleOpen={this.onToggleOpen('showColors')}
                          >
                            {this.renderColorFilters(baseColors)}
                          </Container>
                          <Container
                            label="delivery speed"
                            showToggle={true}
                            open={showDeliverySpeeds}
                            onToggleOpen={this.onToggleOpen('showDeliverySpeeds')}
                          >
                            {this.renderDeliveryFilters(baseSpeeds)}
                          </Container>
                          <Container
                            label="brands"
                            showToggle={true}
                            open={showBrands}
                            onToggleOpen={this.onToggleOpen('showBrands')}
                          >
                            {this.renderBrandFilters(baseBrands)}
                          </Container>
                          <Container
                            label="sizes"
                            showToggle={true}
                            open={showSizes}
                            roundedBottom={true}
                            onToggleOpen={this.onToggleOpen('showSizes')}
                          >
                            {this.renderSizeFilters(baseSizes)}
                          </Container>
                        </div>
                      );
                    }}
                  </QueryProducts>
                </div>
                <div className="d-b mt-1p5 md:d-ib md:w-3/4 md:align-top md:mt-2 md:p-0">
                  <QueryProducts
                    query={QUERY_PRODUCTS}
                    variables={{
                      brands,
                      colors,
                      genders: genderFilters,
                      sizes,
                      styles,
                      speeds,
                      categories,
                      sortBy,
                      page,
                      perPage,
                    }}
                    onCompleted={(data: any) => this.onQueryCompletion(data)}
                  >
                    {({ error, loading, data, fetchMore, variables }) => {
                      if (loading) return (
                        <div className="loading-overlay w-full h-full flex justify-center items-center p-absolute pin bgc-white-70" >
                          <Loading size="large"/>
                        </div>
                      )
                      if (error) {
                        console.error(error);
                        return <div>error</div>;
                      }

                      return (
                        <WithInfiniteScroll
                          onPaginatedNext={() => {
                            if (loading) return;
                            if (!data) return;
                            if (data!.filterProducts.pageMetadata.page >= data!.filterProducts.pageMetadata.pages)
                              return;
                            this.moveToNextPage(fetchMore, variables, data)();
                          }}
                          loading={!lastPageReached && (loading || pageLoading)}
                        >
                          <div className="pl-1p5 pr-1 lg:pr-0">
                            <ProductCardContainer
                              key={'productCardContainer'}
                              isLoading={!lastPageReached && (loading || pageLoading)}
                              productCardsProps={this.mapProducts(data)}
                            />
                          </div>
                        </WithInfiniteScroll>
                      );
                    }}
                  </QueryProducts>
                </div>
                <div className="w-full md:d-n">
                  <MobileBottomFilterControls
                    onFilterButtonClick={this.toggleMobileFilters}
                    onGoTopButtonClick={this.onGoToTopButtonClick}
                  />
                </div>
                {showMobileFilters && (
                  <div className="d-b overflow-y-scroll p-fixed pin bgc-white md:d-n">
                    <ContainerFilterMobile>
                      <Header
                        title="Filter"
                        onClose={this.toggleMobileFilters}
                        onReset={this.onMobileFiltersResetButtonClick}
                      />
                    </ContainerFilterMobile>
                    <ContainerFilterMobile>
                      <LabelFilterMobile text="Sort By" />
                      <ContainerLeftFilterButtonMobile
                        options={this.createSortByMobileFilterButtonObjects(selectedTopRightFilterIndex)}
                      />
                    </ContainerFilterMobile>
                    <ContainerFilterMobile>
                      <LabelFilterMobile text="Gender" />
                      <ContainerLeftFilterButtonMobile
                        options={this.createGenderMobileFilterButtonObjects(baseGenders)}
                        showSelected={true}
                      />
                    </ContainerFilterMobile>
                    <ContainerFilterMobile>
                      <LabelFilterMobile text="Delivery Speed" />
                      <ContainerLeftFilterButtonMobile
                        options={this.createDeliverySpeedMobileFilterButtonObjects(baseSpeeds)}
                        showSelected={true}
                      />
                    </ContainerFilterMobile>
                    <ContainerFilterMobile>
                      <LabelFilterMobile text="Sizes" />
                      <ContainerLeftFilterButtonMobile
                        options={this.createSizeMobileFilterButtonObjects(baseSizes)}
                        showSelected={true}
                      />
                    </ContainerFilterMobile>
                    <ContainerFilterMobile>
                      <LabelFilterMobile
                        text="Colors"
                        showSelected={true}
                        selected={colors}
                        showTool={true}
                        onToolClick={this.toggleColorMobileFilters}
                      />
                    </ContainerFilterMobile>
                    <ContainerFilterMobile>
                      <LabelFilterMobile
                        text="Brands"
                        showSelected={true}
                        selected={brands}
                        showTool={true}
                        onToolClick={this.toggleBrandMobileFilters}
                      />
                    </ContainerFilterMobile>
                    <ContainerFilterMobile>
                      <PrimaryButton block={true} onClick={this.toggleMobileFilters}>
                        {`View ${count} Results`}
                      </PrimaryButton>
                    </ContainerFilterMobile>
                  </div>
                )}
                {showMobileColorFilters && this.renderColorMobileFilters(baseColors, colors || [])}
                {showMobileBrandFilters && this.renderBrandMobileFilters(baseBrands, brands || [])}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
